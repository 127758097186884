<template>
  <div class="body">
    <!-- banner区域 Start -->
    <div class="banner">
      <m-top-bar></m-top-bar>
    </div>
    <!-- banner区域 End -->

    <!-- switchBar区域 Start -->
    <div class="switchBar">
      <div class="switchBox">移动MESH无线视频覆盖传输（非视距、移动点）</div>
    </div>
    <div class="line"></div>
    <!-- switchBar区域 End -->

    <!-- container区域 Start -->
    <div class="container">
      <div class="containerBox">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LINKOM推出的室外系列网桥可应用于1KM-60KM的点对点、点对多点及移动无线视频传输，
        高宽带、安全抗干扰及天线内外置可选设计，搭配无线网络管理软件平台内置google地图引擎，
        实时定位部署在线监测，时间告警等管理功能，广泛应用于智能楼宇、交通运输、渡轮码头、森林防火等安防视频传输领域。<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点对点、点对多点高带宽传输，支持高达900Mbps高带宽传输；<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点对点可支持50路D1视频传输，可选私有协议I-Poll及W-Jet抗干扰双频5.8G/2.4G传输，点对多点可支持20点分点客户端摄像机连接。
        <img src="../../../../assets/mobile/img/solution/cityPic.jpg" alt="" class="pic">
      </div>
    </div>
    <!-- container区域 End -->

    <!-- baseBar区域 Start -->
    <m-base-bar></m-base-bar>
    <!-- baseBar区域 End -->
  </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
  name: 'Support',
  components: {
    mTopBar,
    mBaseBar,
  }
}
</script>

<style scoped>
.body {
  background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
  width: 100%;
  min-height: 2050px;
  background: url(../../../../assets/mobile/img/solution/banner.jpg) center no-repeat;
  background-size: 335%;
}

/* switchBar区域 */
.switchBar {
  padding-top: 50px;
  width: 100%;
  height: 300px;
  text-align: center;
  background-color: #ffffff;
}

.switchBar .switchBox {
  margin: 0 auto;
  text-align: center;
  width: 1700px;
  height: 200px;
  font-size: 72px;
  line-height: 200px;
  color: #ffffff;
  border-radius: 30px;
  background-color: #c3a35d;
}

.line {
  margin: 0 auto;
  width: 90%;
  border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
  padding: 50px;
  width: 100%;
  min-height: 500px;
}

.container .containerBox {
  padding: 100px;
  width: 100%;
  min-height: 300px;
  font-size: 70px;
  text-align: justify;
  background-color: #ffffff;
}

.container .containerBox .pic {
  margin-top: 150px;
  width: 1600px;
}
</style>